import React from 'react';
import './PleaseRotate.scss';

const PleaseRotate = () => (
	<div className="PleaseRotate">
		<h1>
			Hey! Please rotate your device{' '}
			<span role="img" aria-label="emoji">
				🤠
			</span>
		</h1>
	</div>
);

export default PleaseRotate;
