import React from 'react';

import './Instructions.scss';

const Instructions = () => {
	return (
		<div className="Instructions">
			<h1>
				<span style={{ display: 'block' }}>Swipe or press a button to choose your answer.</span> Good luck!
			</h1>
			<img src={process.env.PUBLIC_URL + '/assets/img/swipe.svg'} alt="swipe" />
		</div>
	);
};

export default Instructions;
