import firstQuestion from './assets/img/01_etui.svg';
import secondQuestion from './assets/img/02_building.svg';
import thirdQuestion from './assets/img/03_casino.svg';
import fourthQuestion from './assets/img/04_environment.svg';
import fifthQuestion from './assets/img/05_meeting.svg';
import sixthQuestion from './assets/img/06_cancer.svg';
import seventhQuestion from './assets/img/07_gap.svg';
import eighthQuestion from './assets/img/08_wage.svg';

import ETUI from './components/SVG/ETUI';
import Building from './components/SVG/Building';
import Casino from './components/SVG/Casino';
import Environment from './components/SVG/Environment';
import Meeting from './components/SVG/Meeting';
import Cancer from './components/SVG/Cancer';
import Gap from './components/SVG/Gap';
import Wage from './components/SVG/Wage';

export default [
	{
		question:
			'Where was strongest <strong>real wage growth</strong> since 2009?',
		imgOld: eighthQuestion,
		img: Wage,
		correct: 'Bulgaria',
		wrong: 'Sweden',
		// Swipe right for correct
		value: '1',
	},
	{
		question:
			'Is democracy at work linked with the <strong>pay gap</strong> between top management and workers?',
		imgOld: seventhQuestion,
		img: Gap,
		correct: 'Linked with lower pay gaps',
		wrong: 'Linked with higher pay gaps',
		// Swipe left for correct
		value: '-1',
	},
	{
		question:
			'How many people die every year in the European Union due to a <strong>work related cancer</strong>?',
		imgOld: sixthQuestion,
		img: Cancer,
		correct: '> 100.000',
		wrong: '26.300',
		// Swipe right for correct
		value: '1',
	},
	{
		question:
			'What was the date of the publication of the recast <strong>EWC (European Work’s Council)</strong> directive?',
		imgOld: fifthQuestion,
		img: Meeting,
		correct: '2009',
		wrong: '1999',
		// Swipe right for correct
		value: '1',
	},
	{
		question:
			'Which of those countries are spending the most in measures to control <strong>climate change</strong>?',
		imgOld: fourthQuestion,
		img: Environment,
		correct: 'China',
		wrong: 'Austria',
		// Swipe left for correct
		value: '-1',
	},
	{
		question: 'Who uses the expression <strong>Casino Capitalism</strong>?',
		imgOld: thirdQuestion,
		img: Casino,
		correct: 'John Monks',
		wrong: 'Donald Trump',
		/// Swipe left for correct
		value: '-1',
	},
	{
		question: 'In 2015, the <strong>ETUC</strong> held its Congress in',
		imgOld: secondQuestion,
		img: Building,
		correct: 'Paris',
		wrong: 'Brussels',
		// Swipe left for correct
		value: '-1',
	},
	{
		question:
			'Is <strong>ETUI</strong> the acronym for the European Trade Unions Institution?',
		imgOld: firstQuestion,
		img: ETUI,
		correct: 'No',
		wrong: 'Yes',
		// Swipe right for correct
		value: '1',
	},
];
