import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowScroll from '@react-hook/window-scroll';
import star from '../../assets/img/star.svg';
import whiteStar from '../../assets/img/star_white.svg';
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from 'react-share';
import Footer from '../../components/Footer/Footer';
import win from '../../assets/img/gif/win-loop.gif';
import lose from '../../assets/img/gif/lose-loop.gif';

import './Check.scss';

const Check = props => {
	const [renderGif, setRenderGif] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setRenderGif(true);
		}, 2000);
	}, []);

	useEffect(() => {
		props.removeParentFixed();
	}, []);

	const scrollY = useWindowScroll();

	const shareUrl = window.location.protocol + '//' + window.location.hostname;
	let image = null;
	let msg = '';
	let msgWinner = [];
	let msg2 = [];
	let message = null;
	let isWinner = null;
	let howManyOrangeStars = props.correct;

	if (props.correct === props.length) {
		image = renderGif ? win : null;
		msg = 'Congratulations, you are a';
		msgWinner.push('Winner!');
		message = (
			<span>
				Come to the stand and claim your prize! To learn more,{' '}
				<a href="https://mailchi.mp/c5d0df91e357/etui" className="LeaveEmail">
					<strong>leave us your email</strong>
				</a>
				.
			</span>
		);
		isWinner = true;
	} else {
		image = renderGif ? lose : null;
		msg = 'Good job, you dit it!';
		message = (
			<span>
				Come to the stand for the correct answers and to learn more! If you
				want, 
				<a href="https://mailchi.mp/c5d0df91e357/etui" className="LeaveEmail">
					<strong>leave us your email</strong>
				</a>
				.
			</span>
		);
		isWinner = false;
	}

	// Push stars
	for (let i = 0; i < props.length; i++) {
		// If should be orange, then it's orange yeeee
		if (howManyOrangeStars > 0) {
			msg2.push(whiteStar);
		} else {
			msg2.push(star);
		}
		howManyOrangeStars--;
	}

	return (
		<div className={`Check ${scrollY !== 0 ? 'scrolled' : ''}`}>
			<div className="ScrollHint">
				<span>
					<i className="fas fa-chevron-down" />
				</span>
			</div>
			<div className="Top">
				<h2>{msg}</h2>
				{isWinner ? msgWinner.map((el, i) => <h1 key={i}>{el}</h1>) : null}
				<div className="Result">
					<span className="Score">
						{props.correct}/<strong>{props.length}</strong>
					</span>
					<div className="Stars">
						{msg2.map((el, i) => (
							<img key={i} src={el} alt="star" />
						))}
					</div>
				</div>
			</div>
			<div className="Character">
				<img src={image} alt="" />
			</div>
			<div className="Message">{message}</div>
			{/* Normal href which causes the page to reload */}
			<a href="/quiz" className="PlayAgain">
				Play again
			</a>
			<div className="Message">Share:</div>
			<div className="ShareButtons">
				<FacebookShareButton
					url={shareUrl}
					quote={'Like me: play and learn with ETUI #ETUC19 #ETUIAPP'}
					className="Facebook"
				>
					<i className="fab fa-facebook-square" />
				</FacebookShareButton>
				<TwitterShareButton
					url={shareUrl}
					title="Like me: play and learn with ETUI"
					hashtags={['ETUC19', 'ETUIAPP']}
					via="ETUI_org"
					className="Twitter"
				>
					<i className="fab fa-twitter-square" />
				</TwitterShareButton>
				<WhatsappShareButton
					url={shareUrl}
					title="Like me: play and learn with ETUI #ETUC19 #ETUIAPP 👉"
					className="Whatsapp"
				>
					<i className="fab fa-whatsapp-square" />
				</WhatsappShareButton>
			</div>
			<div className="PageLinks">
				<Link to="/agenda" className="PageLink">
					See the Agenda
				</Link>
				<Link to="/" className="PageLink">
					Back Home
				</Link>
			</div>
			<Footer />
		</div>
	);
};

export default Check;
