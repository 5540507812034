import React from 'react';
import orangeFooter from '../../assets/img/footer.svg';
import darkFooter from '../../assets/img/footer_dark.svg';

import './Footer.scss';

const Footer = props => {
	const footer = {
		background: props.dark
			? `url(${darkFooter}) no-repeat top center`
			: `url(${orangeFooter}) no-repeat top center`,
		backgroundSize: 'cover',
	};
	return (
		<div className="Footer" style={footer}>
			<div className="FooterTitle">You can also find us on</div>
			<div className="Socials">
				<a
					href="https://www.facebook.com/etuibrussels/"
					target="_blank"
					rel="noopener noreferrer"
					title="Facebook"
				>
					<i className="fab fa-facebook-square" />
				</a>
				<a
					href="https://twitter.com/ETUI_org"
					target="_blank"
					rel="noopener noreferrer"
					title="Twitter"
				>
					<i className="fab fa-twitter-square" />
				</a>
				<a
					href="https://www.instagram.com/etui_org/"
					target="_blank"
					rel="noopener noreferrer"
					title="Instagram"
				>
					<i className="fab fa-instagram" />
				</a>
				<a
					href="https://www.linkedin.com/company/european-trade-union-institute/"
					target="_blank"
					rel="noopener noreferrer"
					title="LinkedIn"
				>
					<i className="fab fa-linkedin" />
				</a>
				<a
					href="https://www.etui.org/"
					target="_blank"
					rel="noopener noreferrer"
					title="Web"
				>
					<i className="fas fa-globe" />
				</a>
			</div>
		</div>
	);
};

export default Footer;
