import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { withTracker } from './hoc/Tracking/withTracker';

import Quiz from './containers/Quiz/Quiz';
import Home from './containers/Home/Home';
import Events from './containers/Events/Events';
import NotFound from './containers/NotFound/NotFound';

import './styles/Base.scss';

const App = () => {
	useEffect(() => {}, []);
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={withTracker(Home)} />
				<Route exact path="/quiz" component={withTracker(Quiz)} />
				<Route exact path="/agenda" component={withTracker(Events)} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	);
};

export default App;
