import React from 'react';

const Building = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 669.3 469.38">
		<g>
			<g>
				<path
					d="M449.5 8.5A136.88 136.88 0 0 1 435.23 0H0v280c73-117 277-203 277-86 0 265 175.5 305.5 285.5 258.5 85-39 112-124 106-210-15.2-121.13-111.22-181.96-219-234z"
					fill="#f9861e"
				/>
				<clipPath id="buildingClip">
					<path
						d="M184.11 445.12c145.89-29 272.37 68.89 397.61-3.24L584.1 63H172s4.9 389.33 12.11 382.12z"
						fill="none"
					/>
				</clipPath>
				<g clipPath="url(#buildingClip)">
					<path fill="#ccddea" d="M244.89 253.45H510.8v214.4H244.89z" />
					<path
						fill="#72a0c9"
						d="M446.4 414.57v-1.83h4.85V399h-4.85v-1.84h4.85v-13.74h-4.85v-1.83h4.85v-13.74h-4.85v-1.83h4.85v-13.75h-4.85v-1.83h4.85V336.7h-4.85v-1.83h4.85v-13.74h-4.85v-1.83h4.85v-13.75h-4.85v-1.83h4.85v-13.74h-4.85v-1.83h4.85v-13.74h-4.85v-1.84h4.85v-13.74h-4.85V257h4.85v-13.74h-4.85v-1.83h4.85v-13.74h-4.85v-1.84h4.85v-13.74h-4.85v-1.83h4.85v-13.74h-4.85v-1.83h4.85v-13.75h-4.85v-1.83h4.85v-13.74h-4.85v-1.83h4.85v-13.74h-4.85v-1.84h4.85v-26.56H336.33v26.56h4.72v1.84h-4.72v13.74h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.75h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.74h4.72v1.84h-4.72v13.74h4.72v1.83h-4.72V257h4.72v1.83h-4.72v13.74h4.72v1.84h-4.72v13.74h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.75h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.75h4.72v1.83h-4.72v13.74h4.72v1.83h-4.72v13.74h4.72V399h-4.72v13.74h4.72v1.83h-4.72v54.05h114.92v-54.05h-4.85z"
					/>
					<path
						fill="#415c72"
						d="M207.3 170.89h114.91v297.73H207.3zm231.77 297.73h-90.56l1.83-377.43h86.9l1.83 377.43z"
					/>
					<path fill="#91c1e5" d="M465.37 221.27h95.54v247.35h-95.54z" />
					<path fill="#415c72" d="M479.11 253.34h68.06v215.28h-68.06z" />
					<path
						strokeMiterlimit="10"
						stroke="#91c1e5"
						fill="none"
						d="M487.82 262.04h50.65v152.53h-50.65z"
					/>
					<path fill="#415c72" d="M479.11 212.11h68.06v9.16h-68.06z" />
					<path fill="#72a0c9" d="M213.44 161.73h101.04v9.16H213.44z" />
					<path
						className="Building_mult delay2"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
						d="M547.17,468.62l-68.06,0l0,-46.03l68.06,-37.74l0,83.77Zm13.56,-0.19l-13.31,0l0,-98.86l13.31,-8.09l0,106.95Z"
					/>
					<path fill="#263d54" d="M465.37 231.35h95.54v9.16h-95.54z" />
					<path fill="#142330" d="M207.3 179.13h114.51v16.49H207.3z" />
					<path
						strokeWidth="2"
						stroke="#020202"
						strokeMiterlimit="10"
						fill="none"
						d="M369.05 148.9h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.58h49.47m-49.47 15.57h49.47m-49.47 15.57h49.47"
					/>
					<path
						className="Building_mult"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
						d="M263.18,161.73l51.3,0l0,9.16l7.33,0l0,297.73l-58.63,0l0,-306.89Z"
					/>
					<path
						strokeWidth="2"
						stroke="#020202"
						strokeMiterlimit="10"
						fill="none"
						d="M485.79 212.11v-24.73m6.41 24.73v-12.82"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#91c1e5"
						fill="none"
						d="M487.36 270.92h51.56m-51.56 10.26h51.56m-51.56 10.27h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56m-51.56 10.26h51.56M528.53 262.5v152.07M518.27 262.5v152.07M508.01 262.5v152.07M497.75 262.5v152.07"
					/>
					<path
						strokeWidth="2"
						strokeMiterlimit="10"
						stroke="#91c1e5"
						fill="none"
						d="M223.79 467.7V215.78m13.74 251.92V215.78m13.74 251.92V215.78m13.74 251.92V215.78m13.74 251.92V215.78m13.74 251.92V215.78m13.75 251.92V215.78"
					/>
					<path
						className="Building_mult delay1"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
						d="M450.98,147.98l0,-26.56l-13.86,0l-0.14,-30.23l-43.45,0l0,377.43l57.45,0l0,-54.05l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.84l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.75l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.75l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.84l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.84l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.75l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.83l4.84,0l0,-13.74l-4.84,0l0,-1.84l4.84,0Z"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default Building;
