import React, { useState, useEffect } from 'react';
import Questions from '../../components/Questions/Questions';
import checkRotation from '../../hoc/checkRotation';
import PleaseRotate from '../../hoc/PleaseRotate/PleaseRotate';
import Instructions from '../../components/Instructions/Instructions';

import './Quiz.scss';

const Quiz = props => {
	const [quizClass, setNotFixed] = useState('Quiz');
	const [instrOpen, setInstr] = useState(false);

	useEffect(() => {
		// For rendering the instruction only once
		if (!localStorage.getItem('renderInstr')) {
			localStorage.setItem('renderInstr', true);
			setInstr(true);
			closeInstrHandler();
		}
	}, []);

	const closeInstrHandler = () => {
		setTimeout(() => {
			setInstr(false);
		}, 5000);
	};

	let content = null;
	let hasShadow = '';
	if (props.isLandscape) {
		content = <PleaseRotate />;
		hasShadow = ' NoShadow';
	} else {
		content = (
			<React.Fragment>
				{instrOpen ? <Instructions /> : null}
				<Questions changeQuizCss={() => setNotFixed('NotFixedQuiz')} />
			</React.Fragment>
		);
	}
	return <div className={quizClass + hasShadow}>{content}</div>;
};

export default checkRotation(Quiz);
