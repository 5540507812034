import React from 'react';
import PropTypes from 'prop-types';
import { animated, interpolate } from 'react-spring/hooks';
import Dots from '../../components/Dots/Dots';
import checkBrowser from '../../checkBrowser';

import './Question.scss';

const createMarkup = q => {
	return { __html: q };
};

const Question = props => {
	const { i, x, y, bind, data, gone } = props;
	const { question } = data[i];
	let QuestionImg = data[i].img;
	if (checkBrowser()) {
		QuestionImg = data[i].imgOld;
		console.log('ok');
	}
	let answers = [];
	// If the correct answer should stay on the right, then put it in the right position
	if (parseInt(data[i].value) === 1) {
		answers.push({
			answer: data[i].wrong,
			isCorrect: false,
			dir: -1,
		});
		answers.push({
			answer: data[i].correct,
			isCorrect: true,
			dir: 1,
		});
	} else {
		answers.push({
			answer: data[i].correct,
			isCorrect: true,
			dir: -1,
		});
		answers.push({
			answer: data[i].wrong,
			isCorrect: false,
			dir: 1,
		});
	}

	return (
		<animated.div
			className={`Question ${gone.has(i) ? 'out' : 'in'} ${
				gone.size + i + 1 === data.length ? 'current' : ''
			}`}
			key={i}
			style={{
				transform: interpolate(
					[x, y],
					(x, y) => `translate3d(${x}px,${y}px,0)`
				),
			}}
		>
			<animated.div
				className="QuestionCard"
				{...bind(i)}
				style={{
					transform: interpolate([x, y], (x, y) =>
						x < -10
							? 'scale(0.9) rotate(-1.5deg)'
							: x > 10
							? 'scale(0.9) rotate(1.5deg)'
							: 'scale(1) rotate(0deg)'
					),
					boxShadow: interpolate([x, y], (x, y) =>
						x > -5 && x < 5 ? 'none' : '0 0 15px -1px #0003'
					),
					borderRadius: interpolate([x, y], (x, y) =>
						x > -5 && x < 5 ? '0' : '10px'
					),
				}}
			>
				<div className="Top">
					{checkBrowser() ? (
						<img src={QuestionImg} draggable="false" alt="questionImage" />
					) : (
						<QuestionImg />
					)}
				</div>
				<div className="Bottom">
					<span
						className="_Question"
						dangerouslySetInnerHTML={createMarkup(question)}
					/>
					<div className="Answers">
						{answers.map((el, i) => (
							<button
								key={i}
								onClick={() => props.clickedAnswer(props.i, el.dir)}
							>
								{el.answer}
							</button>
						))}
					</div>
					<div className="Indicator">
						<Dots active={data.length - i - 1} />
					</div>
				</div>
			</animated.div>
		</animated.div>
	);
};

Question.propTypes = {
	question: PropTypes.string,
	correct: PropTypes.string,
	wrong: PropTypes.string,
};

export default Question;
