import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.svg';
import LogoCongress from '../../assets/img/logo_congress.png';
import checkRotation from '../../hoc/checkRotation';
import PleaseRotate from '../../hoc/PleaseRotate/PleaseRotate';
import Footer from '../../components/Footer/Footer';

import './Home.scss';

const Home = props => {
	let content = null;
	if (props.isLandscape) {
		content = <PleaseRotate />;
	} else {
		content = (
			<React.Fragment>
				<div className="Top">
					<div className="Character">
						<img
							src={process.env.PUBLIC_URL + '/assets/img/home-loop.gif'}
							alt="character"
						/>
						<span className="HomeLogo">
							<img src={Logo} alt="Logo of ETUI" />
						</span>
					</div>
					<div className="CTA">
						<span className="desc">Play and learn with ETUI</span>
						<h1>Take the Quiz</h1>
						<span className="Description">
							<span
								style={{
									fontWeight: '700',
									color: '#fff',
									display: 'block',
									textAlign: 'left',
									marginBottom: '5px',
									fontSize: '15px'
								}}
							>
								How much do you know about Europe and trade unions?
							</span>
							<span
								style={{
									color: '#fff',
									fontSize: '16px',
									display: 'block',
									width: '190px',
									textAlign: 'left'
								}}
							>
								Test your knowledge now and win a smart pen!
							</span>
						</span>
					</div>
				</div>
				<Link to="/quiz" className="QuizButton">
					Play !
				</Link>
				<div className="Bottom">
					{/*<h3>Etui at Etuc congress</h3>
          <span className="Tag">#ETUC19</span>*/}
					<span className="LogoCongress">
						<img src={LogoCongress} alt="Logo of ETUC Vienna Congress" />
					</span>
					<h2>ETUI at ETUC Congress</h2>
					<Link to="/agenda" className="EventsButton">
						Agenda
					</Link>
				</div>
				<Footer />
			</React.Fragment>
		);
	}

	return <div className="Home">{content}</div>;
};

export default checkRotation(Home);
