import React, { useEffect, useState } from 'react';

const checkRotation = (WrappedComponent) => {
	const HOC = () => {
		useEffect(() => {
			const mql = window.matchMedia('(orientation: portrait)');
			screenTest(mql);
			// Listen to device orientation change
			mql.addListener(screenTest);
			// Unbind event listeners
			return () => {
				mql.removeListener(screenTest);
			};
		}, []);

		const [isLandscape, landScapeHandler] = useState(false);

		const screenTest = (e) => {
			if (/Android|webOS|iPhone|iPod|iPad|Opera Mini/i.test(navigator.userAgent)) {
				// If there are matches, we're in portrait
				if (e.matches) {
					landScapeHandler(false);
				} else {
					landScapeHandler(true);
				}
			}
		};

		return <WrappedComponent isLandscape={isLandscape} />;
	};

	return HOC;
};

export default checkRotation;
