import React from 'react';
import { Link } from 'react-router-dom';
import back from '../../assets/img/back.svg';
import backInverted from '../../assets/img/back_inverted.svg';
import logo from '../../assets/img/logo_white.svg';
import checkRotation from '../../hoc/checkRotation';
import PleaseRotate from '../../hoc/PleaseRotate/PleaseRotate';
import Footer from '../../components/Footer/Footer';

import './Events.scss';

const NoBorder = {
	borderBottom: 'none',
	paddingBottom: 0,
};

const Events = props => {
	let content = null;
	if (props.isLandscape) {
		content = <PleaseRotate />;
	} else {
		content = (
			<React.Fragment>
				<div className="Title">
					<div className="TitleContent">
						<Link className="Back" to="/">
							<img src={back} alt="back" />
						</Link>
						<span className="TitleInfo">
							<img src={logo} alt="logo" className="topLogo" />
							<h2>Agenda</h2>
							<span className="Duration">21 - 23 May 2019</span>
						</span>
					</div>
				</div>
				<div className="EventsList">
					<div className="DayBlock">
						<span className="Day">
							Tuesday, <strong>May 21st</strong>
						</span>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								8h15 - 9h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle">ETUI Breakfast meeting</div>
						<div className="Description">
							Launch of the book: The future of Europe/ Jetzt für ein besseres
							Europa
							<div className="Speakers">
								<strong>Speakers</strong>: Peter Scherrer, Deputy General
								Secretary of the ETUC Juliane Bir, Senior Advisor ETUC and
								Wolfgang Kowalsky, Senior Advisor ETUC
							</div>
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								9h - 17h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								ETUI office room
							</div>
						</div>
						<div className="EventTitle">
							The Transnational Trade Union Rights Experts Network (TTUR)
							network meeting
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								13h - 14h30
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle">ETUI fringe meeting</div>
						<div className="Description">
							Pub Quiz on democracy (at work)
							<div className="Speakers">
								<span style={{ display: 'block' }}>
									<strong>Moderator</strong>: Aline Hoffmann
								</span>
								<strong>Head of unit</strong>: Europeanisation of industrial
								relations, ETUI
							</div>
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								18h30 - 20h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Exhibition Hall
							</div>
						</div>
						<div className="EventTitle">ETUC & ETUI reception </div>
						<div className="Description" style={NoBorder}>
							<strong>Opening of the ETUI Exhibition</strong> Bread & Roses :
							another history of trade union posters
							<br />
							<br />
							<strong>ETUC Brian Bercusson award</strong>{' '}
							<div className="Speakers">
								<strong>With</strong>: Luca Visentini, ETUC General Secretary
								and Niklas Bruun, ETUI TTUR Coordinator
							</div>
						</div>
					</div>
					<div className="DayBlock">
						<span className="Day">
							Wednesday, <strong>May 22nd</strong>
						</span>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								8h15 - 9h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle">ETUI Breakfast meeting</div>
						<div className="Description">
							<strong>Brian Bercusson award 2019</strong>: EU Economic
							governance and the Right to Collective Bargaining
							<div className="Speakers">
								<strong>With</strong>: Pieter Pecinovsky, legal counsel with the
								law firm ‘Van Olmen &amp; Wynant’ and winner of the 2019 ETUC
								Brian Bercusson Award
							</div>
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								9h - 17h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								ETUI office room
							</div>
						</div>
						<div className="Description">
							<strong>TRANSFER</strong> editorial meeting
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								11.30h - 12h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								ETUI stand
							</div>
						</div>
						<div className="EventTitle">
							Book signature session with Professor Joseph E. Stiglitz Columbia
							University
						</div>
						<div className="Description">
							People, Power and Profits: Progressive capitalism for an age of
							discontent
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								13h - 14.30h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								ETUI stand
							</div>
						</div>
						<div className="EventTitle">Presentation</div>
						<div className="Description" style={NoBorder}>
							Everything you always wanted to know about Works Councils
							<div className="Speakers">
								<strong>Speaker</strong>: Stan De Spiegelaere, ETUI Senior
								researcher
							</div>
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								13h - 14.30h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle" style={{ marginTop: '10px' }}>
							ETUI fringe meeting: Green/ Just transition
						</div>
						<div className="Description" style={NoBorder}>
							<div className="Speakers">
								<span style={{ display: 'block' }}>
									<strong>Speaker</strong>: Laurent Berger, General Secretary
									CFDT and President-elect of the ETUC
								</span>
								<strong>Moderator</strong>: Philippe Pochet, General Director of
								the ETUI
							</div>
						</div>
					</div>
					<div className="DayBlock" style={NoBorder}>
						<span className="Day">
							Thursday, <strong>May 23rd</strong>
						</span>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								8h15 - 9h
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle">ETUI Breakfast meeting</div>
						<div className="Description">
							<strong>Book launch</strong>: Collective Bargaining in Europe:
							Towards an endgame
							<div className="Speakers">
								<strong>Speakers</strong>: Torsten Müller, ETUI Senior
								researcher, Jeremy Waddington, ETUI Associate Researcher
							</div>
						</div>
						<div className="TimeRoomBlock">
							<div className="Time">
								<i className="far fa-clock" />
								13h - 14h30
							</div>
							<div className="Room">
								<i className="fas fa-map-marker-alt" />
								Schubert 4-6 1 st floor
							</div>
						</div>
						<div className="EventTitle">ETUI fringe meeting</div>
						<div className="Description" style={NoBorder}>
							Future of work with a foresight perspective
							<div className="Speakers">
								<span style={{ display: 'block' }}>
									<strong>Introduction</strong>: Aida Ponce del Castillo, Senior
									Researcher – Foresight, ETUI
								</span>
								<span style={{ display: 'block' }}>
									<strong>Facilitators</strong>: Ilaria Costantini and Bruno
									Demaître, Education Officers, ETUI
								</span>
								<strong>Conclusions</strong>: Thiébaut Weber outgoing Confederal
								Secretary, ETUC and Ludovic Voets Confederal Secretary elect,
								ETUC
							</div>
						</div>
					</div>
				</div>

				<Link className="Back lonely" to="/">
					<img src={backInverted} alt="back" /> Home
				</Link>
				<Footer dark />
			</React.Fragment>
		);
	}
	return <div className="Events">{content}</div>;
};

export default checkRotation(Events);
