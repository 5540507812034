import React from 'react';

const Environment = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660.28 467.44">
		<defs>
			<clipPath id="envClip" transform="translate(-23.36)">
				<path
					d="M588.27 424.38c-17.79 9.51-66.9 33.25-144 41.43a534.62 534.62 0 0 1-108.07.16c-52.19-4.93-74.34-12.91-109-22.39-48.29-13.19-141-6.32-204.91 21.84L0 477.44 318.74 96.88l48.08 6.18L586.64 305z"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					d="M97.5 0c12.63 112.38-82 145.85-54.22 289 23.66 121.67 181.64 162.28 199 166 28 6 116.22 18.47 190 9 72.21-9.27 130.84-27.73 170-77 31-39 40-54 58-87V0z"
					fill="#f9861e"
				/>
				<g clipPath="url(#envClip)">
					<path fill="#2e4561" d="M139.04 298L0 467.14h391.89l-149.2-181.2" />
					<path
						d="M247.06 291c-13.33-.65-23.4 1.51-30.84 4.16-10.3 3.68-17.05 8.83-31.87 10.82a75 75 0 0 1-29.91-1.62 66.87 66.87 0 0 1-15.4-6.36l56.9-69.23z"
						fill="#fff"
					/>
					<path
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
						d="M196.85 229.92l21.76 74.83-36.43 68.94 29.98 92.88H390.9L196.85 229.92z"
					/>
					<path
						fill="#2e4561"
						d="M295.49 263.22L140.82 467.14h435.93L410.79 248.69"
					/>
					<path
						d="M415.64 254.75c-14.83-.79-26 1.81-34.31 5-11.46 4.43-19 10.65-35.46 13a77.15 77.15 0 0 1-33.23-1.9 71.34 71.34 0 0 1-17.14-7.63l63.3-83.45z"
						fill="#fff"
					/>
					<path
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
						d="M359.79 181.15L384 271.36l-40.53 83.12 33.35 111.97h198.83l-215.86-285.3z"
					/>
					<path
						fill="#72a0c9"
						d="M499.81 381.11l5.92-1.08-6.16 3.54-2.65 13.78 6.51-1.38-6.79 4.05-2.71 14.08 8.11-2.18-8.48 5.44-2.7 14.01 10.72-3.51-11.27 7.73-2.53 13.05 13.34-4.84-14.05 10.02-2.58 13.32h25.52l-.27-144.26-9.93 58.23z"
					/>
					<path
						fill="#91c1e5"
						d="M520 381.11l-5.93-1.08 6.16 3.54 2.66 13.78-6.51-1.38 6.78 4.05 2.71 14.08-8.11-2.18 8.49 5.44 2.7 14.01-10.72-3.51 11.27 7.73 2.52 13.05-13.33-4.84 14.05 10.02 2.57 13.32h-25.52l-.16-144.26L520 381.11z"
					/>
					<path
						fill="#72a0c9"
						d="M446.2 351.61l7.96-1.45-8.28 4.76-3.56 18.5 8.74-1.85-9.11 5.44-3.64 18.91 10.89-2.94-11.39 7.32-3.63 18.81 14.4-4.72-15.13 10.38-3.39 17.53 17.9-6.5-18.87 13.45-3.46 17.89h34.27l-.36-193.72-13.34 78.19z"
					/>
					<path
						fill="#91c1e5"
						d="M473.31 351.61l-7.95-1.45 8.27 4.76 3.56 18.5-8.74-1.85 9.11 5.44 3.64 18.91-10.89-2.94 11.4 7.32 3.62 18.81-14.4-4.72 15.14 10.38 3.39 17.53-17.91-6.5 18.87 13.45 3.46 17.89h-34.27l-.22-193.72 13.92 78.19z"
					/>
				</g>
				<path
					className="Env_cloud"
					d="M486.64 111.34h-8.18c-8 0-13.33-4.1-13.36-11.44.09-1.21.15-2.43.15-3.66a45.62 45.62 0 0 0-84.61-23.71 32.4 32.4 0 0 0-50 27.17 33.63 33.63 0 0 0 .2 3.65c-.18 5-7 7.71-14.67 8h-22a5.035 5.035 0 0 0 0 10.07h192.47a5.035 5.035 0 0 0 0-10.07z"
					fill="#fff"
				/>
				<g className="Env_birdR">
					<path
						d="M455.64 131.36a15.92 15.92 0 0 0-12.63-18.62 16.59 16.59 0 0 0-3-.28 15.91 15.91 0 0 1 9.7 17.76z"
						fill="#91c1e5"
					/>
					<path
						d="M449.69 130.22a16 16 0 0 1 18.83-12.59 16.85 16.85 0 0 1 3 .86 16 16 0 0 0-15.75 12.88z"
						fill="#72a0c9"
					/>
				</g>
				<g className="Env_birdL">
					<path
						d="M362.37 100.93a12.08 12.08 0 0 0-16.73-8.2 12.09 12.09 0 0 1 12.25 9.27z"
						fill="#91c1e5"
					/>
					<path
						d="M357.89 102a12.16 12.16 0 0 1 9.1-14.6 11.79 11.79 0 0 1 2.32-.33 12.13 12.13 0 0 0-6.88 13.84z"
						fill="#72a0c9"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default Environment;
