import React from 'react';

const Wage = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 642.1 489.39">
		<defs>
			<clipPath id="wageClip">
				<path
					d="M631 220C615.66 112.6 546.82 40 496 0H0v77c38.51 28.38 87.62 74.51 132 144 59.61 93.34 46.11 127.19 90 190 58 83 217.28 102.45 306 47 136-85 104.19-229.67 103-238z"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					d="M631 220C615.66 112.6 546.82 40 496 0H0v77c38.51 28.38 87.62 74.51 132 144 59.61 93.34 46.11 127.19 90 190 58 83 217.28 102.45 306 47 136-85 104.19-229.67 103-238z"
					fill="#f9861e"
				/>
				<g clipPath="url(#wageClip)">
					<g className="Wage_upperHand">
						<path
							d="M438 232.71h34.59a30.33 30.33 0 0 0 18.41-6.92l23.06-18.45-6.92-9.22-69 11.53z"
							fill="#8db5c9"
						/>
						<path
							fill="#fff"
							d="M543.762 202.624l-23.598 15.584-48.29-73.124 23.599-15.584z"
						/>
						<path
							fill="#234d63"
							d="M642.088 150.068l-92.425 61.036-59.46-90.039L582.63 60.03z"
						/>
						<g className="Wage_banknote">
							<path fill="#234d63" d="M248.89 195.81h189.1v87.63h-189.1z" />
							<circle cx="342.39" cy="240.67" r="33.06" fill="#436675" />
							<path
								d="M350 245.64l4 2.05a12.21 12.21 0 0 1-10.79 6c-6.52 0-11.83-3.82-13.52-9.74h-2.1v-2.7h1.61c0-.48-.08-1-.08-1.45s0-1 .08-1.53h-1.61v-2.69h2.14c1.69-5.88 7-9.66 13.48-9.66A12.16 12.16 0 0 1 354 232l-4 2.05a8 8 0 0 0-6.76-3.91 8.89 8.89 0 0 0-8.41 5.48h10.87v2.69H334c0 .49-.08 1-.08 1.53s0 1 .08 1.45h11.64V244h-10.89a8.91 8.91 0 0 0 8.45 5.55 7.89 7.89 0 0 0 6.8-3.91z"
								fill="#f2f2f2"
							/>
						</g>
						<path
							d="M481.8 161.22h-71.49a32.51 32.51 0 0 0-20.75 6.92l-36.9 27.67h64.57l-48.22 21a11.28 11.28 0 0 0-4.68 2l-.14.05a11.5 11.5 0 0 0 11.62 19.69l57.54-24.34c6.92-2.3 10.64-2.68 13.84-2.3l27.67 2.3c6.17.05 11.6-1.39 16.15-4.61l16.14-11.53z"
							fill="#adcde0"
						/>
					</g>
					<g className="Wage_lowerHand">
						<path
							d="M466.06 353.78a22.48 22.48 0 0 0-20.76-4.61l-39.2 16.14c-8.46 3.36-16.92 2.79-25.37 0l-34.59-13.84c-4-2.66.62-9.58 4.61-6.92l28.69 10.33A10.34 10.34 0 1 0 387.7 336l-49.63-22.56a57.34 57.34 0 0 0-39.2 0L262 334.18l15 63.41 25.37-6.91c7-1.57 11.53-2.31 23.06 0l48.43 9.22a75.25 75.25 0 0 0 36.89-2.31l57.65-36.89c2.27-2.31-.04-4.61-2.34-6.92z"
							fill="#adcde0"
						/>
						<path
							d="M272.35 379.15l25.36-6.92c7-1.57 11.54-2.31 23.07 0l50.73 13.83c16.14 4.62 23.06 4.62 34.59 0l60-32.28c2.3 2.31 4.61 4.61 2.3 6.92l-57.65 36.89a75.25 75.25 0 0 1-36.89 2.31l-48.43-9.22c-11.53-2.31-16.08-1.57-23.06 0L277 397.59z"
							fill="#8db5c9"
						/>
						<path
							fill="#fff"
							d="M232.559 328.496l27.385-7.056 21.867 84.858-27.386 7.056z"
						/>
						<path
							fill="#234d63"
							d="M161.695 336.08l68.048-17.534 26.924 104.486-68.047 17.535z"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Wage;
