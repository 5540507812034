import React from 'react';

const Meeting = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 717 487.41">
		<g>
			<g>
				<path
					d="M0 0v303c40.2 39.85 138.76 122.53 295 135 28.55 2.28 238 32 323-70 37.72-45.26-8.08-109.83 25-199 26.54-71.55 74-101.31 74-145 0-1.54-1.18-17.91-1.73-24z"
					fill="#f9861e"
				/>
				<g className="Meeting_personBackR">
					<circle cx="550.55" cy="182.05" r="33.71" fill="#354e66" />
					<path
						d="M600.53 269.19c-.27-25.12-22.85-45.41-50.67-45.41s-50.4 20.29-50.67 45.41v102.27c0 9.88 22.69 17.89 50.68 17.89s50.68-8 50.68-17.89V269.19z"
						fill="#354e66"
					/>
					<path
						d="M531.29 182.05c0-17 10.4-31.13 23.91-33.39a33.71 33.71 0 1 0 0 66.77c-13.51-2.26-23.91-16.35-23.91-33.38zM524.41 371.46V269.19c.23-21.19 16.33-38.94 38.06-44a56 56 0 0 0-12.61-1.43c-27.82 0-50.4 20.29-50.67 45.41v102.29c0 9.88 22.69 17.89 50.68 17.89 4.36 0 8.58-.19 12.61-.56-21.89-1.98-38.07-8.99-38.07-17.33z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
				</g>
				<g className="Meeting_personBackL">
					<circle cx="233.83" cy="171.05" r="33.71" fill="#354e66" />
					<path
						d="M214.56 171.05c0-17 10.41-31.13 23.92-33.39a33.71 33.71 0 1 0 0 66.77c-13.48-2.26-23.92-16.35-23.92-33.38z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
					<path
						d="M283.81 258.19c-.27-25.12-22.85-45.41-50.67-45.41s-50.4 20.29-50.67 45.41v102.27c0 9.88 22.69 17.89 50.68 17.89s50.68-8 50.68-17.89V258.19z"
						fill="#354e66"
					/>
					<path
						d="M207.68 360.46V258.19c.23-21.19 16.33-38.94 38.06-44a56.09 56.09 0 0 0-12.61-1.43c-27.82 0-50.4 20.29-50.67 45.41v102.29c0 9.88 22.69 17.89 50.68 17.89 4.35 0 8.58-.19 12.61-.56-21.89-1.98-38.07-8.99-38.07-17.33z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
				</g>
				<ellipse cx="382" cy="369.02" rx="219" ry="74.99" fill="#386789" />
				<g className="Meeting_personBackC">
					<circle cx="392.3" cy="144.3" r="33.71" fill="#354e66" />
					<path
						d="M442.29 231.44C442 206.32 419.44 186 391.62 186s-50.4 20.28-50.68 45.4v102.32c0 9.88 22.69 17.89 50.68 17.89s50.68-8 50.68-17.89V231.44z"
						fill="#354e66"
					/>
					<path
						d="M373 144.3c0-17 10.41-31.12 23.92-33.39a34.08 34.08 0 0 0-4.66-.32 33.71 33.71 0 0 0 0 67.42 34.08 34.08 0 0 0 4.66-.32C383.45 175.42 373 161.34 373 144.3zM366.16 333.72V231.44c.23-21.18 16.33-38.93 38.06-44a55.52 55.52 0 0 0-12.6-1.44c-27.82 0-50.4 20.28-50.68 45.4v102.32c0 9.88 22.69 17.89 50.68 17.89 4.35 0 8.58-.2 12.61-.56-21.89-1.98-38.07-8.99-38.07-17.33z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
				</g>
				<path fill="#386789" d="M593 354h8v16h-8zM163 354h8v16h-8z" />
				<ellipse cx="382" cy="355.26" rx="219" ry="74.99" fill="#72a0c9" />
				<g className="Meeting_personFrontL">
					<circle cx="230.47" cy="269.73" r="33.71" fill="#91c1e5" />
					<path
						d="M280.46 356.87c-.27-25.12-22.86-45.41-50.68-45.41s-50.4 20.29-50.67 45.41v102.28c0 9.87 22.69 17.88 50.68 17.88s50.68-8 50.68-17.88V356.87z"
						fill="#91c1e5"
					/>
					<path
						d="M204.33,459.15l0,-102.28c0.23,-21.19 16.33,-38.94 38.06,-44c-4.138,-0.961 -8.372,-1.444 -12.62,-1.44c-27.82,0 -50.4,20.29 -50.67,45.41l0,102.31c0,9.87 22.69,17.88 50.68,17.88c4.36,0 8.58,-0.19 12.62,-0.56c-21.817,-1.963 -37.962,-8.933 -38.07,-17.32Zm6.88,-189.42c0,-17 10.4,-31.12 23.92,-33.39c-1.544,-0.213 -3.101,-0.32 -4.66,-0.32c-18.493,0 -33.71,15.217 -33.71,33.71c0,18.493 15.217,33.71 33.71,33.71c1.559,0 3.116,-0.107 4.66,-0.32c-13.495,-2.266 -23.881,-16.297 -23.92,-33.39Z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
				</g>
				<g className="Meeting_personFrontR">
					<circle cx="480.78" cy="280.1" r="33.71" fill="#91c1e5" />
					<path
						d="M530.76 367.25c-.27-25.12-22.85-45.41-50.67-45.41s-50.4 20.29-50.68 45.41v102.27c0 9.88 22.69 17.89 50.68 17.89s50.68-8 50.68-17.89V367.25z"
						fill="#91c1e5"
					/>
					<path
						d="M454.63,469.52l0,-102.27c0.23,-21.19 16.33,-38.94 38.06,-44c-4.136,-0.952 -8.366,-1.432 -12.61,-1.43c-27.82,0 -50.4,20.29 -50.68,45.41l0,102.29c0,9.88 22.69,17.89 50.68,17.89c4.35,0 8.58,-0.2 12.61,-0.56c-21.807,-1.973 -37.952,-8.943 -38.06,-17.33Zm6.88,-189.42c0,-17 10.41,-31.12 23.92,-33.38c-1.54,-0.221 -3.094,-0.332 -4.65,-0.33c-18.487,0.009 -33.694,15.223 -33.694,33.71c0,18.493 15.217,33.71 33.71,33.71c1.55,0 3.099,-0.107 4.634,-0.32c-13.51,-2.26 -23.92,-16.35 -23.92,-33.39Z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e2e2e2"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default Meeting;
