import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => (
	<div className="NotFound">
		<span className="Error">404</span>
		<h1>Ooops! Looks like you're lost.</h1>
		<Link to="/" className="BackHome">
			Back Home
		</Link>
	</div>
);

export default NotFound;
