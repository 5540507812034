const checkBrowser = () => {
	// Browser detection
	const isOpera = navigator.userAgent.indexOf(' OPR/') >= 0;
	const isIE = false || !!document.documentMode;
	const isEdge = !isIE && !!window.StyleMedia;
	let changeBrowser = false;
	if (isOpera || isIE || isEdge) {
		changeBrowser = true;
	}
	return changeBrowser;
};

export default checkBrowser;
