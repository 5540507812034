import React from 'react';
import data from '../../data.js';

import './Dots.scss';

const Dots = (props) => {
	const len = data.length;
	let dots = [];
	for (let i = 0; i < len; i++) {
		dots.push({
			isActive: i === props.active ? true : false,
		});
	}
	return (
		<div className="Dots">
			{dots.map((el, i) =>
				el.isActive ? (
					<div className="Dot Active" key={i}>
						{props.active + 1}
					</div>
				) : (
					<div className="Dot" key={i} />
				)
			)}
		</div>
	);
};

export default Dots;
