import React from 'react';

const Casino = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 585.75 493.53">
		<defs>
			<clipPath id="casinoClip" transform="translate(-92.75 -176.5)">
				<path
					d="M671.66 405l.79.52-.79.09v.6l-.77-.51c-145 16.48-226 148.52-226 148.52-109.84 160.83-260.06 65-260.06 65L0 420l3-303L276 0l324 51z"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					d="M83.5 0c-3.25 68.15-32.11 145.72-56.45 182.58-53.87 89.76-24.78 206.19 65 260.06 89.76 53.87 206.19 24.78 260.06-65 0 0 88.63-137.2 233.66-153.67v-224z"
					fill="#f9861e"
				/>
				<g clipPath="url(#casinoClip)">
					<circle cx="228.37" cy="284.81" r="92.94" fill="#fff" />
					<path
						d="M146.15 285.7a83.41 83.41 0 0 1 166.82 0"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e5e5e5"
						opacity=".52"
					/>
					<path
						d="M147.54 286.59H88.87a140.09 140.09 0 0 0 140.09 140.1v-59.67a81.44 81.44 0 0 1-81.42-80.43z"
						fill="#72a0c9"
					/>
					<path
						d="M310.38 286.59a81.43 81.43 0 0 1-81.42 80.43v59.67a140.09 140.09 0 0 0 140.1-140.1h-58.68z"
						fill="#91c1e5"
					/>
					<path
						d="M310.4 285.59v1h58.68A140.09 140.09 0 0 0 228.96 146.5v57.66a81.44 81.44 0 0 1 81.44 81.43z"
						fill="#72a0c9"
					/>
					<path
						d="M147.53 285.59a81.43 81.43 0 0 1 81.43-81.43V146.5A140.09 140.09 0 0 0 88.87 286.59h58.67c0-.33-.01-.66-.01-1z"
						fill="#91c1e5"
					/>
					<g className="Casino_outerDial">
						<path
							d="M136.53 196.36l-26.28-26.28a169.13 169.13 0 0 0-35.8 52.82l34.19 14.6a131.72 131.72 0 0 1 27.89-41.14zM229.06 157.83v-37.16a168.43 168.43 0 0 0-62.67 12.23l13.86 34.49a131.29 131.29 0 0 1 48.81-9.56z"
							fill="#263d54"
						/>
						<path
							d="M180.25 167.39l-13.86-34.49a169 169 0 0 0-56.14 37.18l26.27 26.28a131.64 131.64 0 0 1 43.73-28.97zM229.56 157.83a131.08 131.08 0 0 1 51.05 10.27l14.64-34.19a168.19 168.19 0 0 0-65.64-13.24h-.5v37.16z"
							fill="#354e66"
						/>
						<path
							d="M361.11 288.88h37.14a168 168 0 0 0-12.37-63l-34.49 13.86a130.85 130.85 0 0 1 9.72 49.14z"
							fill="#263d54"
						/>
						<path
							d="M351.41 239.73l34.49-13.86a169 169 0 0 0-37.55-56.28l-26.27 26.27a131.71 131.71 0 0 1 29.33 43.87z"
							fill="#354e66"
						/>
						<path
							d="M322.08 195.86l26.27-26.27a168.92 168.92 0 0 0-53.1-35.68l-14.64 34.19a131.71 131.71 0 0 1 41.47 27.76z"
							fill="#263d54"
						/>
						<path
							d="M108.64 237.5l-34.19-14.6a168.26 168.26 0 0 0-13.6 66h37.16a131 131 0 0 1 10.63-51.4z"
							fill="#354e66"
						/>
						<path
							d="M229.56 420.95h-.5v37.15h.5a168.28 168.28 0 0 0 62.65-12l-13.85-34.49a131.24 131.24 0 0 1-48.8 9.34z"
							fill="#263d54"
						/>
						<path
							d="M177.3 410.15l-14.59 34.18a168.06 168.06 0 0 0 66.35 13.77v-37.16a131.12 131.12 0 0 1-51.76-10.79zM278.36 411.59l13.89 34.49a168.93 168.93 0 0 0 56.65-37.39l-26.27-26.28a131.66 131.66 0 0 1-44.27 29.18zM398.25 288.88h-37.14v.51a131 131 0 0 1-10.43 51.41l34.19 14.59a168.31 168.31 0 0 0 13.4-66c-.02-.17-.02-.34-.02-.51z"
							fill="#354e66"
						/>
						<path
							d="M322.59 382.41l26.27 26.28a169 169 0 0 0 36-53.3l-34.17-14.59a131.66 131.66 0 0 1-28.1 41.61zM98 289.39v-.51H60.85v.51a168.33 168.33 0 0 0 11.88 62.3l34.52-13.85A131.29 131.29 0 0 1 98 289.39zM136.04 381.91l-26.28 26.27a169 169 0 0 0 53 36.15l14.59-34.18a131.81 131.81 0 0 1-41.31-28.24z"
							fill="#263d54"
						/>
						<path
							d="M107.25 337.84l-34.49 13.85a169.1 169.1 0 0 0 37 56.49l26.28-26.27a131.82 131.82 0 0 1-28.79-44.07z"
							fill="#354e66"
						/>
					</g>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M229.41 387.61v23.24M229.71 163.91v23.23"
					/>
					<rect
						x="229.56"
						y="388.47"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="224.8"
						y="164.46"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M129.33 287.23h-23.24M353.03 287.53H329.8"
					/>
					<rect
						x="208.11"
						y="455.54"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(90 252.37 331.64)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="432.12"
						y="450.77"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(90 476.375 326.875)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M158.58 358.15l-16.43 16.43M316.97 200.18l-16.43 16.43"
					/>
					<rect
						x="242.55"
						y="533.9"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(45 411.605 344.423)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="397.57"
						y="372.14"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(45 566.631 182.656)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M158.79 216.39l-16.43-16.42M316.76 374.79l-16.43-16.43"
					/>
					<rect
						x="239.18"
						y="375.77"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(135 231.738 279.036)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="400.94"
						y="530.8"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(135 393.508 434.063)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M189.94 379.45l-9.15 21.36M278.34 173.95l-9.16 21.35"
					/>
					<rect
						x="278.07"
						y="556.87"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(23.2 663.98 253.381)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="361.93"
						y="349.1"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(23.2 747.85 45.63)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M137.49 247.76l-21.36-9.16M342.99 336.15L321.63 327"
					/>
					<rect
						x="216.21"
						y="411.3"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(113.2 230.418 303.187)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="423.98"
						y="495.16"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(113.2 438.187 387.041)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M136.44 324.47l-21.57 8.63M344.26 241.66l-21.58 8.63"
					/>
					<rect
						x="216.99"
						y="496.89"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(68.2 303.345 350.86)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="423.21"
						y="409.28"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(68.2 509.561 263.239)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						strokeMiterlimit="10"
						stroke="#262626"
						strokeLinecap="round"
						strokeWidth="3"
						fill="none"
						d="M192.47 194.26l-8.63-21.58M275.28 402.07l-8.63-21.57"
					/>
					<rect
						x="276.19"
						y="350.22"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(158.4 249.257 263.391)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<rect
						x="363.81"
						y="556.43"
						width="4.77"
						height="21.45"
						rx="1.63"
						ry="1.63"
						transform="rotate(158.2 336.817 469.971)"
						style={{ mixBlendMode: 'multiply' }}
						fill="#eaeaea"
					/>
					<path
						d="M229.56 148.29a140.1 140.1 0 1 0 140.1 140.09 140.1 140.1 0 0 0-140.1-140.09zm0 264.61a124.52 124.52 0 1 1 124.51-124.52A124.51 124.51 0 0 1 229.56 412.9z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#ededed"
					/>
					<path
						d="M229.56 157.91a131.67 131.67 0 1 0 131.69 131.66 131.66 131.66 0 0 0-131.69-131.66z"
						stroke="#fff"
						strokeWidth="4"
						strokeMiterlimit="10"
						fill="none"
					/>
					<path
						fill="#263d54"
						d="M215.48 97.74L228.37 88l12.89 9.74-12.89 9.74-12.89-9.74zM215.48 483.79l12.89-9.74 12.89 9.74-12.89 9.74-12.89-9.74zM412.85 290.76l9.74-12.88 9.74 12.88-9.74 12.9-9.74-12.9zM26.8 290.76l9.74-12.88 9.74 12.88-9.74 12.9-9.74-12.9z"
					/>
					<path
						d="M229.56 202.3h-.6v83.4h84a83.41 83.41 0 0 0-83.4-83.4z"
						style={{ mixBlendMode: 'multiply' }}
						fill="#e5e5e5"
						opacity=".52"
					/>
					<g className="Casino_cross">
						<rect
							x="316.88"
							y="421.09"
							width="10.86"
							height="81.02"
							rx="3.72"
							ry="3.72"
							transform="rotate(8.6 1449.573 -243.477)"
							fill="#030b19"
						/>
						<rect
							x="316.88"
							y="421.09"
							width="10.86"
							height="81.02"
							rx="3.72"
							ry="3.72"
							transform="rotate(98.6 351.848 333.458)"
							fill="#030b19"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Casino;
