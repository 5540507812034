import React from 'react';

import './Modal.scss';

const Modal = (props) => {
	return (
		<div className="Modal">
			<div className="ModalContent">{props.children}</div>
		</div>
	);
};

export default Modal;
