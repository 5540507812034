import React from 'react';

const Cancer = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 480.22">
		<defs>
			<clipPath id="cancerClip">
				<path
					d="M0 0v40c45.85 36.58 93.65 89.56 141.5 167.5C232.46 355.65 312 441 357 464c66.46 34 314 27 393-144V0z"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					d="M0 0v40c45.85 36.58 93.65 89.56 141.5 167.5C232.46 355.65 312 441 357 464c66.46 34 314 27 393-144V0z"
					fill="#f9861e"
				/>
				<g clipPath="url(#cancerClip)">
					<path
						d="M405.1 111.5c13.92 0 21.1 3.63 21.1 7.5l.9 59.5 11 11-15 34-18-17h-.6l-15 14-15-34 8-8 .9-59.5c0-3.87 7.18-7.5 21.1-7.5z"
						fill="#354e66"
					/>
					<path
						d="M404.6 114c9.94 0 18 2.24 18 5s-8.06 5-18 5h.4c-9.94 0-18-2.24-18-5s8.06-5 18-5z"
						fill="#263d54"
					/>
					<g className="Cancer_Lungs">
						<g className="Cancer_LungR">
							<path
								d="M246 437s11 17 21 19 31-5 31-5l58-30s11-5 14-14 4-23 4-23z"
								fill="#72a0c9"
							/>
							<path
								d="M352 134s-5-13-15-13-24 8-24 8-35 26-68 94-32 151-32 151v39s0 18 5 31 17 6 17 6 17-6 27-6 30-12 37-17 20-8 27-9 25-7 36-17a44.36 44.36 0 0 1 24-11s14 1 15-33 2-94 2-94-3.57-28.55-10-46c-7-19-25-53-41-83z"
								fill="#9ac0db"
							/>
							<path
								d="M337 121c-10 0-24 8-24 8s-35 26-68 94-32 151-32 151v39s0 18 5 31 17 6 17 6 17-6 27-6c0 0-8.55-181.31 35-259 37-66 40-64 40-64z"
								style={{ mixBlendMode: 'multiply' }}
								fill="#d3d3d3"
								opacity=".38"
							/>
						</g>
						<g className="Cancer_LungR">
							<path
								d="M570 437s-11 17-21 19-31-5-31-5l-58-30s-11-5-14-14-4-23-4-23z"
								fill="#72a0c9"
							/>
							<path
								d="M464 134s5-13 15-13 24 8 24 8 35 26 68 94 32 151 32 151v39s0 18-5 31-17 6-17 6-17-6-27-6-30-12-37-17-20-8-27-9-25-7-36-17a44.36 44.36 0 0 0-24-11s-14 1-15-33-2-94-2-94 3.57-28.55 10-46c7-19 25-53 41-83z"
								fill="#9ac0db"
							/>
							<path
								d="M479 121c10 0 24 8 24 8s35 26 68 94 32 151 32 151v39s0 18-5 31-17 6-17 6-17-6-27-6c0 0 8.55-181.31-35-259-37-66-40-64-40-64z"
								style={{ mixBlendMode: 'multiply' }}
								fill="#d3d3d3"
								opacity=".38"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Cancer;
