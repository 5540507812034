// Based on https://codesandbox.io/embed/j0y0vpz59

// TODO: Refactor a bit the set code (a bit redundant)

import React, { useState } from 'react';
import { useSprings } from 'react-spring/hooks';
import { useGesture } from 'react-with-gesture';
import Div100vh from 'react-div-100vh';
import ReactGA from 'react-ga';

import Question from '../Question/Question';
import Check from '../Check/Check';
import data from '../../data.js';
import Modal from '../Modal/Modal';

import './Questions.scss';
import '../SVG/SVG.scss';

let questionList = null;
let modalMessage = null;

const to = () => ({
	x: 0,
	y: 0, // change if don't want stacked card style
	scale: 1,
});
const from = () => ({ scale: 1 });
const trans = (r, s) => '';

const Questions = props => {
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [quizFinish, setQuizFinish] = useState(false);
	const [gone, updateGone] = useState(() => new Set());
	const [modalIsOpen, modalHandler] = useState(false);

	const [propss, set] = useSprings(data.length, () => ({
		...to(),
		from: from(),
	}));

	const updateCorrectAnswers = () => {
		setCorrectAnswers(correctAnswers => correctAnswers + 1);
	};

	const setFinishQuizHandler = () => {
		setQuizFinish(true);
	};

	const updateGoneHandler = i => {
		updateGone(gone.add(i));
	};

	const openModalHandler = () => {
		modalHandler(true);
	};

	const closeModalHandler = () => {
		setTimeout(() => {
			modalHandler(false);
		}, 2000);
	};

	const fireEventGA = (type, index, n) => {
		ReactGA.event({
			category: 'Answer',
			action:
				'Question ' + n + ': ' + data[index].question.replace(/<[^>]*>/g, ''),
			label:
				'' + type === 'correct'
					? data[index].correct + ' (' + type + ')'
					: data[index].wrong + ' (' + type + ')',
		});
	};

	const chosenAnswer = (index, chosenValue) => {
		updateGoneHandler(index);
		if (chosenValue === parseInt(data[index].value)) {
			fireEventGA('correct', index, data.length - index);
			updateCorrectAnswers();
			modalMessage = (
				<svg
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 130.2 130.2"
				>
					<circle
						className="path circle"
						fill="none"
						stroke="#73AF55"
						strokeWidth="6"
						strokeMiterlimit="10"
						cx="65.1"
						cy="65.1"
						r="62.1"
					/>
					<polyline
						className="path check"
						fill="none"
						stroke="#73AF55"
						strokeWidth="6"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="100.2,40.2 51.5,88.8 29.8,67.5 "
					/>
				</svg>
			);
			openModalHandler();
			closeModalHandler();
		} else {
			fireEventGA('wrong', index, data.length - index);
			modalMessage = (
				<svg
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 130.2 130.2"
				>
					<circle
						className="path circle"
						fill="none"
						stroke="#D06079"
						strokeWidth="6"
						strokeMiterlimit="10"
						cx="65.1"
						cy="65.1"
						r="62.1"
					/>
					<line
						className="path line"
						fill="none"
						stroke="#D06079"
						strokeWidth="6"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="34.4"
						y1="37.9"
						x2="95.8"
						y2="92.3"
					/>
					<line
						className="path line"
						fill="none"
						stroke="#D06079"
						strokeWidth="6"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="95.8"
						y1="38"
						x2="34.4"
						y2="92.2"
					/>
				</svg>
			);
			openModalHandler();
			closeModalHandler();
		}
	};

	// Handling the clicked answer
	const clickedAnswer = (index, chosenValue) => {
		chosenAnswer(index, chosenValue);

		set(i => {
			if (index !== i) return;
			const isGone = gone.has(index);
			const x = isGone ? (200 + window.innerWidth) * chosenValue : 0;
			return {
				x,
				delay: undefined,
				config: { friction: 100, tension: isGone ? 200 : 500 },
			};
		});

		// No more questions to answer
		if (gone.size === data.length) {
			setFinishQuizHandler();
		}
	};

	// Handling the swiped answer
	const bind = useGesture(
		({ args: [index], down, delta: [xDelta], direction: [xDir], velocity }) => {
			const trigger = velocity > 0.2;

			// -1 Left, 1 Right
			const dir = xDir < 0 ? -1 : 1;

			// Down means that the Question is being dragged
			if (!down && trigger) {
				chosenAnswer(index, dir);
			}

			set(i => {
				if (index !== i) return;
				const isGone = gone.has(index);
				const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0;
				return {
					x,
					delay: undefined,
					config: { friction: 100, tension: down ? 800 : isGone ? 200 : 500 },
				};
			});

			// If we want to reset the set and reload the component
			// setTimeout(() => gone.clear() || set((i) => to(i)), 600);

			// No more questions to answer
			if (!down && gone.size === data.length) {
				setFinishQuizHandler();
			}
		}
	);

	questionList = propss.map(({ x, y }, i) => (
		<Question
			key={i}
			i={i}
			x={x}
			y={y}
			trans={trans}
			data={data}
			bind={bind}
			gone={gone}
			clickedAnswer={clickedAnswer}
			length={data.length}
		/>
	));

	return (
		<React.Fragment>
			{quizFinish ? (
				<React.Fragment>
					{modalIsOpen ? <Modal>{modalMessage}</Modal> : null}
					<Check
						correct={correctAnswers}
						length={data.length}
						removeParentFixed={props.changeQuizCss}
					/>
				</React.Fragment>
			) : (
				<Div100vh className="Container">
					{modalIsOpen ? <Modal>{modalMessage}</Modal> : null}
					{questionList}
				</Div100vh>
			)}
		</React.Fragment>
	);
};

export default Questions;
