import React from 'react';

const Gap = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 471">
		<defs>
			<clipPath id="gapClip">
				<path
					d="M0 0v102.54c35.31 36.22 128.92 30.57 177 99.35 43.4 62.07 51.44 138.22 89 185.22C354.63 498 532 469 559 463.56c78-15.68 147.8-61.71 191-93.29V0z"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					d="M0 0v102.54c35.31 36.22 128.92 30.57 177 99.35 43.4 62.07 51.44 138.22 89 185.22C354.63 498 532 469 559 463.56c78-15.68 147.8-61.71 191-93.29V0z"
					fill="#f9861e"
				/>
				<g clipPath="url(#gapClip)">
					<g className="Gap_leftStack">
						<path
							strokeLinecap="round"
							strokeMiterlimit="10"
							stroke="#354e66"
							strokeWidth="3"
							fill="none"
							d="M190.49 415.01h212.4"
						/>
						<rect
							x="245.08"
							y="404.62"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M335.4 404.62a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91H247a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11H247a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M249.31 413.77h19l9.16-9.15h-19.01l-9.15 9.15zM275.36 413.77l9.15-9.15h-4.93l-9.15 9.15h4.93zM319 413.77l9.16-9.15h-3.52l-9.16 9.15H319z"
						/>
						<rect
							x="253.53"
							y="393.36"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M343.85 393.36a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.41a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.41m0-2.11h-88.41a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.41a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M257.75 402.51h19.01l9.16-9.15h-19.01l-9.16 9.15zM283.8 402.51l9.15-9.15h-4.92l-9.15 9.15h4.92zM327.45 402.51l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="245.79"
							y="382.09"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M336.1 382.09A1.92 1.92 0 0 1 338 384v5.34a1.92 1.92 0 0 1-1.91 1.91H247.7a1.92 1.92 0 0 1-1.91-1.91V384a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.34a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4V384a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M250.01 391.25h19.01l9.15-9.16h-19.01l-9.15 9.16zM276.06 391.25l9.15-9.16h-4.93l-9.15 9.16h4.93zM319.71 391.25l9.15-9.16h-3.52l-9.15 9.16h3.52z"
						/>
						<rect
							x="254.94"
							y="370.83"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M345.25 370.83a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M259.16 379.98h19.01l9.15-9.15h-19.01l-9.15 9.15zM285.21 379.98l9.15-9.15h-4.92l-9.16 9.15h4.93zM328.86 379.98l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="257.75"
							y="359.57"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M348.07 359.57a1.91 1.91 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.92-1.91v-5.33a1.91 1.91 0 0 1 1.92-1.91h88.4m0-2.12h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M261.98 368.72h19.01l9.15-9.16h-19.01l-9.15 9.16zM288.03 368.72l9.15-9.16h-4.93l-9.15 9.16h4.93zM331.68 368.72l9.15-9.16h-3.52l-9.15 9.16h3.52z"
						/>
						<rect
							x="254.94"
							y="348.3"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M345.25 348.3a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M259.16 357.45h19.01l9.15-9.15h-19.01l-9.15 9.15zM285.21 357.45l9.15-9.15h-4.92l-9.16 9.15h4.93zM328.86 357.45l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
					</g>
					<g className="Gap_rightStack">
						<path
							strokeLinecap="round"
							strokeMiterlimit="10"
							stroke="#354e66"
							strokeWidth="3"
							fill="none"
							d="M416.11 454.68h212.4"
						/>
						<rect
							x="470.7"
							y="444.29"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M561 444.29a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.39a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91H561m0-2.11h-88.39a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4H561a4 4 0 0 0 4-4v-5.31a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M474.93 453.44h19.01l9.15-9.15h-19.01l-9.15 9.15zM500.98 453.44l9.15-9.15h-4.93l-9.15 9.15h4.93zM544.62 453.44l9.16-9.15h-3.52l-9.16 9.15h3.52z"
						/>
						<rect
							x="479.15"
							y="433.03"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M569.47 433a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.41a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.41m0-2.11h-88.41a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.41a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M483.38 442.18h19l9.16-9.15h-19.01l-9.15 9.15zM509.42 442.18l9.16-9.15h-4.93l-9.15 9.15h4.92zM553.07 442.18l9.16-9.15h-3.52l-9.16 9.15h3.52z"
						/>
						<rect
							x="471.41"
							y="421.76"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M561.72 421.76a1.92 1.92 0 0 1 1.91 1.91V429a1.92 1.92 0 0 1-1.91 1.92h-88.4a1.92 1.92 0 0 1-1.91-1.92v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4V429a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M475.63 430.92h19.01l9.15-9.16h-19.01l-9.15 9.16zM501.68 430.92l9.15-9.16h-4.93l-9.15 9.16h4.93zM545.33 430.92l9.15-9.16h-3.52l-9.15 9.16h3.52z"
						/>
					</g>
					<g className="Gap_upperStack">
						<rect
							x="412.12"
							y="217.02"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M501.63 217a2.72 2.72 0 0 1 2.71 2.71v3.73a2.72 2.72 0 0 1-2.71 2.71h-86.8a2.72 2.72 0 0 1-2.71-2.71v-3.73a2.72 2.72 0 0 1 2.71-2.71h86.8m0-3h-86.8a5.71 5.71 0 0 0-5.71 5.71v3.73a5.71 5.71 0 0 0 5.71 5.71h86.8a5.71 5.71 0 0 0 5.71-5.71v-3.73a5.71 5.71 0 0 0-5.71-5.71z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M416.34 226.17h19.01l9.15-9.15h-19.01l-9.15 9.15zM442.39 226.17l9.15-9.15h-4.93l-9.15 9.15h4.93zM486.04 226.17l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="421.27"
							y="205.75"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M510.78 205.75a2.73 2.73 0 0 1 2.71 2.72v3.72a2.73 2.73 0 0 1-2.71 2.72H424a2.73 2.73 0 0 1-2.71-2.72v-3.72a2.73 2.73 0 0 1 2.71-2.72h86.8m0-3H424a5.72 5.72 0 0 0-5.71 5.72v3.72a5.72 5.72 0 0 0 5.71 5.72h86.8a5.72 5.72 0 0 0 5.71-5.72v-3.72a5.72 5.72 0 0 0-5.71-5.72z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M425.49 214.91h19.01l9.15-9.16h-19.01l-9.15 9.16zM451.54 214.91l9.15-9.16h-4.92l-9.16 9.16h4.93zM495.19 214.91l9.15-9.16h-3.52l-9.15 9.16h3.52z"
						/>
						<rect
							x="424.09"
							y="194.49"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M513.6 194.49a2.72 2.72 0 0 1 2.71 2.71v3.73a2.72 2.72 0 0 1-2.71 2.71h-86.8a2.72 2.72 0 0 1-2.71-2.71v-3.73a2.72 2.72 0 0 1 2.71-2.71h86.8m0-3h-86.8a5.71 5.71 0 0 0-5.71 5.71v3.73a5.71 5.71 0 0 0 5.71 5.71h86.8a5.71 5.71 0 0 0 5.71-5.71v-3.73a5.71 5.71 0 0 0-5.71-5.71z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M428.31 203.64h19.01l9.15-9.15h-19.01l-9.15 9.15zM454.36 203.64l9.15-9.15h-4.93l-9.15 9.15h4.93zM498.01 203.64l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="421.27"
							y="183.23"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M510.78 183.23a2.72 2.72 0 0 1 2.71 2.71v3.72a2.72 2.72 0 0 1-2.71 2.72H424a2.72 2.72 0 0 1-2.71-2.72v-3.72a2.72 2.72 0 0 1 2.71-2.71h86.8m0-3H424a5.71 5.71 0 0 0-5.71 5.71v3.72a5.72 5.72 0 0 0 5.71 5.72h86.8a5.72 5.72 0 0 0 5.71-5.72v-3.72a5.71 5.71 0 0 0-5.71-5.71z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M425.49 192.38h19.01l9.15-9.15h-19.01l-9.15 9.15zM451.54 192.38l9.15-9.15h-4.92l-9.16 9.15h4.93zM495.19 192.38l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="417.05"
							y="171.96"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M506.56 172a2.72 2.72 0 0 1 2.71 2.72v3.72a2.72 2.72 0 0 1-2.71 2.71h-86.8a2.72 2.72 0 0 1-2.71-2.71v-3.72a2.72 2.72 0 0 1 2.71-2.72h86.8m0-3h-86.8a5.72 5.72 0 0 0-5.71 5.72v3.72a5.71 5.71 0 0 0 5.71 5.71h86.8a5.71 5.71 0 0 0 5.71-5.71v-3.72a5.72 5.72 0 0 0-5.71-5.72z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M421.27 181.11h19.01l9.15-9.15h-19.01l-9.15 9.15zM447.32 181.11l9.15-9.15h-4.93l-9.15 9.15h4.93zM490.97 181.11l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="425.49"
							y="160.7"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M515 160.7a2.72 2.72 0 0 1 2.72 2.71v3.73a2.72 2.72 0 0 1-2.72 2.71h-86.79a2.72 2.72 0 0 1-2.72-2.71v-3.73a2.72 2.72 0 0 1 2.72-2.71H515m0-3h-86.79a5.72 5.72 0 0 0-5.72 5.71v3.73a5.72 5.72 0 0 0 5.72 5.71H515a5.72 5.72 0 0 0 5.72-5.71v-3.73a5.72 5.72 0 0 0-5.72-5.71z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M429.72 169.85h19.01l9.15-9.15h-19.01l-9.15 9.15zM455.77 169.85l9.15-9.15h-4.93l-9.15 9.15h4.93zM499.42 169.85l9.15-9.15h-3.52l-9.16 9.15h3.53z"
						/>
						<path
							d="M515.81 158.59H427.4a4 4 0 0 0-4 4v5.33a4.1 4.1 0 0 0 .48 1.91H419a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h.68a3.92 3.92 0 0 0-.48 1.91v5.33a4 4 0 0 0 3.33 4 4 4 0 0 0-.52 2v5.33a4 4 0 0 0 .52 2 4 4 0 0 0-3.33 4V213a4 4 0 0 0 .48 1.91H414a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-.4-1.91h5.61a4 4 0 0 0 4-4v-5.34a4 4 0 0 0-.52-2 4 4 0 0 0 3.33-4v-5.26a4 4 0 0 0-3.33-4 4 4 0 0 0 .52-2v-5.33a4 4 0 0 0-4-4h-.68a4 4 0 0 0 .48-1.91v-5.33a4 4 0 0 0-.48-1.91h4.91a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4.03-4z"
							style={{ mixBlendMode: 'multiply' }}
							fill="#dbdbdb"
						/>
						<rect
							x="360.02"
							y="217.02"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M450.34 217a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.41a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.41m0-2.11h-88.41a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.41a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M364.24 226.17h19.01l9.15-9.15h-19l-9.16 9.15zM390.29 226.17l9.15-9.15h-4.92l-9.15 9.15h4.92zM433.94 226.17l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="368.47"
							y="205.75"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M458.78 205.75a1.92 1.92 0 0 1 1.91 1.91V213a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.34a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4V213a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.34a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M372.69 214.91h19.01l9.15-9.16h-19l-9.16 9.16zM398.74 214.91l9.15-9.16h-4.93l-9.15 9.16h4.93zM442.39 214.91l9.15-9.16h-3.52l-9.15 9.16h3.52z"
						/>
						<rect
							x="360.72"
							y="194.49"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M451 194.49a1.92 1.92 0 0 1 2 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.46a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91H451m0-2.11h-88.37a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4H451a4 4 0 0 0 4-4v-5.31a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M364.95 203.64h19.01l9.15-9.15H374.1l-9.15 9.15zM391 203.64l9.15-9.15h-4.93l-9.15 9.15H391zM434.64 203.64l9.16-9.15h-3.52l-9.15 9.15h3.51z"
						/>
						<rect
							x="369.88"
							y="183.23"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M460.19 183.23a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.12h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M374.1 192.38h19.01l9.15-9.15h-19.01l-9.15 9.15zM400.15 192.38l9.15-9.15h-4.93l-9.15 9.15h4.93zM443.8 192.38l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="372.69"
							y="171.96"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M463 172a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91H463m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4H463a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M376.92 181.11h19.01l9.15-9.15h-19.01l-9.15 9.15zM402.96 181.11l9.16-9.15h-4.93l-9.15 9.15h4.92zM446.61 181.11l9.16-9.15h-3.52l-9.16 9.15h3.52z"
						/>
						<rect
							x="369.88"
							y="160.7"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M460.19 160.7a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M374.1 169.85h19.01l9.15-9.15h-19.01l-9.15 9.15zM400.15 169.85l9.15-9.15h-4.93l-9.15 9.15h4.93zM443.8 169.85l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="365.65"
							y="149.43"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M456 149.43a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.92h-88.44a1.92 1.92 0 0 1-1.91-1.92v-5.33a1.92 1.92 0 0 1 1.91-1.91H456m0-2.11h-88.44a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4H456a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M369.88 158.59h19l9.16-9.16h-19.01l-9.15 9.16zM395.93 158.59l9.15-9.16h-4.93l-9.15 9.16h4.93zM439.57 158.59l9.16-9.16h-3.52l-9.16 9.16h3.52z"
						/>
						<rect
							x="374.1"
							y="138.17"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M464.42 138.17a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91H376a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.41m0-2.11H376a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.41a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M378.32 147.32h19.01l9.16-9.15h-19.01l-9.16 9.15zM404.37 147.32l9.15-9.15h-4.92l-9.16 9.15h4.93zM448.02 147.32l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="366.36"
							y="126.9"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M456.67 126.9a1.92 1.92 0 0 1 1.91 1.92v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.92h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M370.58 136.06h19.01l9.15-9.15h-19.01l-9.15 9.15zM396.63 136.06l9.15-9.15h-4.93l-9.15 9.15h4.93zM440.28 136.06l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="375.51"
							y="115.64"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M465.82 115.64a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91v-5.33a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M379.73 124.79h19.01l9.15-9.15h-19.01l-9.15 9.15zM405.78 124.79l9.15-9.15H410l-9.15 9.15h4.93zM449.43 124.79l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="378.32"
							y="104.38"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M468.64 104.38a1.92 1.92 0 0 1 1.91 1.91v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.92-1.91v-5.33a1.92 1.92 0 0 1 1.92-1.91h88.4m0-2.12h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4v-5.33a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M382.55 113.53h19.01l9.15-9.15H391.7l-9.15 9.15zM408.6 113.53l9.15-9.15h-4.93l-9.15 9.15h4.93zM452.25 113.53l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<rect
							x="375.51"
							y="93.11"
							width="92.23"
							height="9.15"
							rx="2.71"
							ry="2.71"
							fill="#9ac0db"
						/>
						<path
							d="M465.82 93.11a1.92 1.92 0 0 1 1.91 1.89v5.33a1.92 1.92 0 0 1-1.91 1.91h-88.4a1.92 1.92 0 0 1-1.91-1.91V95a1.92 1.92 0 0 1 1.91-1.91h88.4m0-2.11h-88.4a4 4 0 0 0-4 4v5.33a4 4 0 0 0 4 4h88.4a4 4 0 0 0 4-4V95a4 4 0 0 0-4-4z"
							fill="#354e66"
						/>
						<path
							fill="#c5dcea"
							d="M379.73 102.26h19.01l9.15-9.15h-19.01l-9.15 9.15zM405.78 102.26l9.15-9.15H410l-9.15 9.15h4.93zM449.43 102.26l9.15-9.15h-3.52l-9.15 9.15h3.52z"
						/>
						<path
							strokeLinecap="round"
							strokeMiterlimit="10"
							stroke="#354e66"
							strokeWidth="3"
							fill="none"
							d="M323.96 227.82h212.4"
						/>
					</g>
					<g className="Gap_arrowL">
						<path
							d="M409.91 246c0 48.77-77.91 44-89.25 85.13"
							stroke="#fff"
							strokeWidth="6"
							strokeLinecap="round"
							strokeMiterlimit="10"
							fill="none"
						/>
						<path
							fill="#fff"
							d="M315.28 320.03l5.68 10.01 10.01-5.69-1.84 6.66-10.01 5.69-5.68-10.01 1.84-6.66z"
						/>
					</g>
					<g className="Gap_arrowR">
						<path
							d="M408.26 258.4c0 78.51 91.74 78.6 105 141.32"
							stroke="#fff"
							strokeWidth="6"
							strokeLinecap="round"
							strokeMiterlimit="10"
							fill="none"
						/>
						<path
							fill="#fff"
							d="M503.35 392.33l9.64 6.29 6.28-9.65 1.43 6.76-6.29 9.65-9.64-6.29-1.42-6.76z"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Gap;
